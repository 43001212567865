.header{
	padding:0 20px;
	height: 64px;
 	background-color: #FFFFFF;
 	color: #131415;
	border-bottom:1px solid #d3d3d3;
}
.logo{
	margin-top:15px; 
	opacity: 0.9;
	font-size: 25px;
	color: #95A0AA;
	letter-spacing: 0;
	height: 30px;
	
}
.header .header-list{
	display: inline-block;
	margin-top:14px; 
	margin-left:20px; 
	margin-right:0px; 
	font-size: 16px;
	letter-spacing: 0;
	color: #131415;
	padding: 3px 13px;
}
.header .active span{
	color:#1890ff;
	font-weight: 500;
}
.header .header-btn{
	width: 130px;
	text-align: left;
	margin-top :13px; 
	display: inline-block;
	background: rgba(243,245,247 , 0.1);
	border-radius: 4px;
	margin-left: 30px; 
	cursor:pointer;
}
.header .header-btn td{
	color: #FFFFFF;
	font-weight: 300;
	/*height: 40px;*/
	padding: 5px 0;
	font-size: 14px;
}

.header .active a{
	color:#FFFFFF;
}
.header .logo{
	font-size: 30px;
	line-height: 62px;
	color:#1890ff;
}
.header-border{
	border:1px solid #3B6DC5;
	border-radius: 4px;
	color: #3B6DC5 !important;
}
