body{
	/*background-color: #F0F2F5;*/
}
.login-body{
	/*position: absolute;*/
	height:  calc(100% - 65px - 80px);
}

.title{
	font-size: 33px;
    color: #3B6DC5;
    text-align: left;
    margin-bottom: 25px;
    margin-top: 8%;
    font-weight: bold;
    padding-left: 20px;
}

.box{
	border:1px solid #E8E8E8;
	border-radius:5px ;
    width:360px;
    margin:auto;
    margin-top: 16%;
}
.simple-block-header{
	padding: 20px;
	/*border-radius:5px 5px 0 0;*/
	font-size: 24px;
	text-align: center;
	color: #3B6DC5;
}
.simple-block-form{
	padding: 20px 30px 20px 30px;
	border-top: 1px solid #E8E8E8;
}

.login-form {
  max-width: 400px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}