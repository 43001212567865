
.discover-new{
	color: #F75729;
	font-size: 28px !important;
	position: absolute;
	top: -12px;
	left: -4px;
}


